import { httpClient } from './axiosClients'
import { commaSerializer } from './paramSerializers'

export function markInvoicePaid (invoiceId) {
  return httpClient.post(`/api/invoices/${invoiceId}/mark-paid`)
}

export function createEntityInvoiceContact (payload) {
  return httpClient.post('/api/entity-invoice-recipient-groupings', payload)
}

export function deleteEntityInvoiceContact (entityInvoiceContactId) {
  return httpClient.delete(`/api/entity-invoice-recipient-groupings/${entityInvoiceContactId}`)
}

export function updateInvoiceContact (invoiceContactId, payload) {
  return httpClient.patch(`/api/invoice-recipient-groupings/${invoiceContactId}`, payload)
}

export function hasInvoicedCommissionFee (propertyId) {
  return httpClient.get(`/api/properties/${propertyId}/has-invoiced-commission-fee`)
}

export function getInvoices (payload) {
  const { url = '/api/invoices', params } = payload
  return httpClient.get(url, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function loadBoCountInvoiceInformation (invoiceId) {
  return httpClient.post(`/api/invoices/${invoiceId}/bocount-information`)
}

export function getVatCodes () {
  return httpClient.get('/api/vat-codes')
}

export function createCreditNoteForInvoice (invoiceId, payload) {
  return httpClient.post(`/api/invoices/${invoiceId}/crediting/start`, payload)
}

export function getInvoiceEmailData (invoiceId) {
  return httpClient.get(`/api/invoices/${invoiceId}/email-data`)
}

export function isBulkInvoicingPossible (propertyId) {
  return httpClient.get(`/api/properties/${propertyId}/is-bulk-invoicing-possible`)
}
